






import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class CommonDate extends Vue {
  @Prop(String)
  dateString!: string;

  @Prop({ type: String, default: 'date' })
  dateFormat!: 'date'|'date-time'|'time'|string;

  get formattedDate (): string {
    const date = new Date(this.dateString);
    const { lang } = this.$root.$route.params;
    let localeString = 'en-GB';
    if (lang === 'de') {
      localeString = 'de-DE';
    }
    if (this.dateFormat === 'time') {
      return date.toLocaleTimeString(localeString);
    }

    if (this.dateFormat === 'date-time') {
      return date.toLocaleString(localeString);
    }

    return date.toLocaleDateString(localeString);
  }
}
